.to-start {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 4;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: .2s;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 5;
  }

  i {
    color: $black;
    font-size: 24px;
  }
}