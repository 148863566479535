.work {
  position: relative;
  width: 100%;
  height: auto;

  &.dark {
    background-color: $black;
    padding-bottom: 128px;

    .work {
      &-bcg {
        display: none;
      }

      &-line {
        &.dark {
          display: block;
          position: absolute;
          top: 16%;
          left: 0;
          width: 41%;

          @include media-breakpoint-down(xl) {
            width: 28%;
          }

          @include media-breakpoint-down(lg) {
            left: -10%;
          }

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        &-bottom {
          display: none;
          margin-top: 0;

          &.second {
            transform: scale(-1, 1);
            margin-left: 120px;
            width: calc(100% - 120px);
            display: block;
          }
        }
      }

      &-title {
        h1 {
          color:$white;

          &:nth-child(2) {
            color: $black;
            -webkit-text-stroke: 1px $white
          }
        }
      }

      &-roadmap {
        &-item {
          border-color: $white;

          &-head {
            border-bottom-color: $white;

            &-circle {
              background-color: $white;
              
              img {
                display: none;
              }

              .black {
                display: block;
              }
            }

            h4 {
              color: $white;
            }
          }

          p {
            color: $white;
          }
        }
      }

      &-zig-zag {
        &.white {
          display: block;

          @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            right: 24px;
          }
        }

        &.black {
          display: none;
        }
      }
    }
  }

  &-bcg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &-line {
    position: absolute;
    top: 0;
    left: 9%;
    width: 8%;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &.dark {
      display: none;
    }
  }

  &-title {
    padding-top: 94px;

    @include media-breakpoint-down(md) {
      margin-bottom: 36px;
    }

    h1 {
      color: black;
      font-size: 96px;
      text-align: right;
      margin-bottom: 40px;
      
      @include media-breakpoint-down(md) {
        font-size: 54px;
        text-align: center;
        margin-bottom: 18px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 36px;
        margin-bottom: 18px;
      }
      
      &:nth-child(2) {
        color: white;
        -webkit-text-stroke: 2px black;
      }
    }
  }

  &-roadmap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 75px;

    .row {
      height: auto;
      align-items: stretch;

      .col-lg-6 {
        @include media-breakpoint-down(lg) {
          margin-bottom: 30px;
        }
      }
    }

    &-item {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start; 
      border: 3px solid $black;
      position: relative;

      &-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-bottom: 3px solid $black;

        &-circle {
          width: 76px;
          height: 76px;
          background-color: $black;
          display: flex;
          align-items: center;
          justify-content: center;

          .black {
            display: none;
          }

          @include media-breakpoint-down(xl) {
            width: 60px;
            height: 60px;
          }
        }
      }

      h4 {
        color: $black;
        font-weight: 600;
        margin-left: 20px;
        font-size: 16px;

        @include media-breakpoint-down(xl) {
          margin-left: 8px;
        }

        @include media-breakpoint-down(lg) {
          margin-left: 22px;
        }
      }
      p {
        margin: 32px 0px;
        padding: 0px 15px;
        z-index: 2;
        position: relative;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &-zig-zag {

    &.white {
      display: none;
    }

    &.black {
      display: block;
    }

    &-wrap {
      padding-top: 94px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-line {
    &-bottom {
      width: calc(100% + 50px);
      position: relative;
      margin-top: -120px;

      &.second {
        display: none;
      }
    }
  }
}