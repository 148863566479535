.get-started {
  position: relative;
  margin-top: -30px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  
  &-banner {
    position: relative;
    padding: 128px 0px;
    background-color: $black;
    display: flex;  
    flex-direction: column;

    &-line {
      width: calc(100% - 120px);
      height: 18px;

      &.second {
        transform: scale(-1, 1);
        align-self: flex-end;
      }
    } 

    .row {
      padding: 64px 0px;
      justify-content: space-between;
      align-items: center;
    }

    &-content {

      &-left {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
          justify-content: center;
          flex-direction: column;
        }
        
        &-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 48px;

          @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-top: 36px;
            margin-bottom: 48px;
          }

          h2 {
            text-transform: uppercase;
            font-size: 36px;
            color: white;
            line-height: 100%;
            text-align: left;
            margin-bottom: 20px;

            @include media-breakpoint-down(lg) {
              font-size: 24px;
            }

            &:nth-child(2) {
              color: black;
              -webkit-text-stroke: 1px white;
            }
          }
        }
      }

      &-text {
        p {
          color: white;
        }
      }
    }


    &-text {   
      h2 {
        text-align: left;
        font-size: 32px;
        line-height: 41px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }
      
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  &-select {
    position: relative;
    padding-bottom: 128px;

    &-bcg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    
    &-line {
      position: relative;
      width: 100%;
    }

    .container {
      padding-top: 128px;      
    }

    &-menu {
      position: relative;
      list-style: none;
  
      &-item {
        border-radius: 8px;
        margin: 35px 0px;
        color: $gray;
        cursor: pointer;
        transition: .3s;
        max-height: 83px;

        @include media-breakpoint-down(sm) {
          margin: 24px 0px;
        }
  
        &.opened {
          max-height: 300px;
          
          .get-started-select-menu-item-wrap-icon-plus {
            display: none;
          }
          .get-started-select-menu-item-wrap-icon-minus {
            display: block;
          }
          p {
            opacity: 1;
            height: auto;
            margin-top: 35px;
          }
        }
  
        &-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          
          h4 {
            font-size: 24px;
            color: black;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }
          }

          &-icon {
            margin-right: 54px;

            @include media-breakpoint-down(sm) {
              margin-right: 0;
            }

            img {
              user-select: none;

              @include media-breakpoint-down(sm) {
                max-width: 30px;
              }
            }
            
            &-minus {
              display: none;
            }
          }
          
          &-description {
            opacity: 0;
            width: 50%;
            font-size: 18px;
            line-height: 23px;
            margin: 0;
            font-weight: 400;
            transition: .3s;
            height: 0px;

            @include media-breakpoint-down(sm) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
