.consultation {
  position: relative;
  width: 100%;
  padding-bottom: 128px;
  overflow: hidden;
  
  &.isServices {
    .consultation {

      &-services-line {
        display: block;
        width: 80%;
        
        @include media-breakpoint-down(md) {
          display: none;
        }

        &.second {
          width: auto;

        }
      }

      &-line {
        display: none;
      }
    }
  }

  &-bcg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }

  &-line {
    position: absolute;
    left: 8px;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  
  &-text {
    position: relative;
    z-index: 2;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 60px;
        text-transform: uppercase;
        color: black;
        text-align: left;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
          font-size: 38px;
          margin-bottom: 18px;
        }

        &:nth-child(2) {
          color: white;
          font-size: 36px;
          -webkit-text-stroke: 2px black;
          margin-bottom: 0;

          @include media-breakpoint-down(sm) {
            font-size: 24px;
          }
        }
      }
    }

    p {
      margin: 33px 0px 97px 0px;
      font-size: 16px;
      line-height: 150%;

      @include media-breakpoint-down(sm) {
        margin: 40px 0px;
      }
    }

    .button {
      width: 100%;
      padding: 13px 61px;
    }
  }
  
  &-img {
    z-index: 2;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
      margin: 64px 0px;

      img {
        width: 100%;
      }
    }

    &-wrap {
      @include media-breakpoint-down(md) {
        order: -1;
      }
    }
  }

  &-services-line {
    display: none;
    position: relative;
    z-index: 2;
    left: 9%;

    &.second {
      left: calc(9% - 8px);
      position: absolute;
    }
  }
}