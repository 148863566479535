*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: auto;
  width: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: $white;
  position: relative;
  overflow-x: hidden;
}

.container {
  padding-left: 74px;
  padding-right: 74px;

  @include media-breakpoint-down(lg) {
    padding-left: 24px;
    padding-right: 24px;
  }

}

a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-title;
  margin: 0px;
  color: $black-text;
}

h1 {
  font-weight: 600;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1.2;
  color: black;
  font-family: $font-title;
}

h2 {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
}

p {
  color: $black;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  line-height: 24px;
}


ul {
  margin: 0;
  padding: 0;

  &.list-checked {
    list-style: none;
    position: relative;
    line-height: 27px;
    margin: 18px 0px;
    margin-left: 1.5rem;

    @include media-breakpoint-down(md) {
      margin: 24px 0px;
    }

    span {
      font-weight: 300;
      font-size: 16px;
    }

    &-sublist {
      position: relative;
      margin-left: 50px;
      list-style: disc;

      li::before {
        content: "" !important;
      }
    }

    li {
      position: relative;
      // margin-bottom: 8px;

      &::before {
        content: url(../images/icons/check-icon.svg);
        // content: url(../../assets/images/icons/check-icon.svg);
        position: relative;
        margin-right: 12px;
      }
    }
  }
}

img {
  pointer-events: none;
  height: auto;
}

.button {
  min-height: 53px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 45px;
  transition: .3s;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #000;
  color: white;
  transition: .2s;
  
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    mix-blend-mode: difference;
    position: absolute;
    border: 1px solid #fff;
    transition: .2s;

    top: 4px;
    left: 4px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  
  &:hover {
    transform: translate(-2px, -2px);
    color: darken(#fff, 10);
    
    &::before {
      transform: translate(4px, 4px);
      border-color: #000;
    }
  }
  
  &:active {
    opacity: 0.7;
  }


  &.light {
    background-color: white;
    color: black;
    font-weight: 400;
  }
}


.privacy {
  padding-top: 180px;
  padding-bottom: 64px;

  @include media-breakpoint-down(sm) {
    padding-top: 64px;
  }

  h2 {
    text-align: left;
    font-size: 36px;
    margin: 24px 0px;
    color: $black;

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 24px;
    color: $black;
    margin-top: 48px;
    
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }
}