.why {
  position: relative;
  padding: 128px 0px;

  &-bcg  {
    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    top: 0;
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    line-height: 48px;
    color: $black;
    text-transform: uppercase;

    &:nth-child(2) {
      color: $white;
      -webkit-text-stroke: 1px $black;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  p {
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;

    @include media-breakpoint-down(md) {
      margin-top: 36px;
    }
  }
}