.contact {
  position: relative;
  padding: 170px 0px 128px 0px;

  .row {
    justify-content: space-between;
  }

  &-address {
    width: auto;
    left: 0;
    z-index: 2;
    overflow: hidden;
    padding-top: 64px;
    margin-bottom: 127px;
    
    &-wrap {
      background-color: $black;
      margin-right: 48px;
      height: 100%;   
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }

    h3 {
      color: $white;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      margin-top: 8px;
      line-height: 29px;
      color: #c4c4c4;
    }

    &-line {
      width: calc(100% - 120px);
      margin-bottom: 110px;

      &:first-of-type {
        align-self: flex-end;
        transform: scale(-1, 1);
      }
    }
  }

  &-form {
    position: relative;
    padding-right: 74px;

    @include media-breakpoint-down(lg) {
      padding-right: 24px;
    }

    @include media-breakpoint-down(md) {
      padding:24px;
    }


    h2 {
      color: $black;
      margin-bottom: 24px;
      font-size: 36px;
      font-weight: 700;
      text-align: left;
      line-height: 48px;
    }

    p {
      margin-bottom: 48px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
    }


    &-wrap {
      display: flex;
      flex-direction: column;


      input, textarea {
        outline: none;
        padding: 15px 21px;
        border: 1px solid #797979;
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 25.5px;

        @include media-breakpoint-down(sm) {
          padding: 12px 20px;
        }
        
        &::placeholder {
          color: #797979;
        }
      }

      textarea {
        height: 200px !important;
        margin-bottom: 0;
      }

      &-submit {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 48px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        &-privacy {
          display: flex;
          align-items: center;

          @include media-breakpoint-down(md) {
            margin-bottom: 24px;
          }

          p {
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
              font-size: 14px;
            }

            a {
              color: $black;
              font-weight: 300;
              text-decoration: underline;
            }
          }

          &-checkbox {
            input {
              display: none;

              &:checked + label {
                background-color: $black;

                &::after {
                  display: block;
                  transform: rotate(315deg) scale(-1, 1);
                  height: 7px;
                  position: relative;
                  width: 12px;
                  top: -3px;
                  border-bottom: 2px solid white;
                  border-right: 2px solid white;
                }
              }
            }

            label {
              width: 18px;
              height: 18px;
              position: relative;
              background: transparent;
              border: 1px solid $black;
              
              border-radius: 2px;
              cursor: pointer;
              margin-bottom: 0;
              display: flex;
              align-items: center;
              margin-right: 5px;
              justify-content: center;

              &::after {
                content: "";      
              }
            }
          }
        }

        .button {
          margin-top: 36px;
          padding: 14px 77px;
        }
      }
    }
  }
}