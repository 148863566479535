.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  transition: .3s;
  padding: 32px 0px;

  @include media-breakpoint-down(sm) {
    padding: 18px 0px;
  }

  &.opened {
    background-color: white;
  }

  .navbar {
    padding: 0;
    transition: .5s all;

    &-brand {
      img {
        @include media-breakpoint-down(sm) {
          max-width: 180px;
        }
      }
    }

    &-mobile {
      transition: .5s all;
      display: flex;
      justify-content: flex-end;

      &-img {
        display: none;

        @include media-breakpoint-down(lg) {
          display: block;
          margin-bottom: 24px;
        }
      }

      @include media-breakpoint-down(lg) {
        position: fixed;
        top: 0;
        justify-content: space-between;
        opacity: 0;
        z-index: 15;
        left: -100%;
        height: 100vh;
        width: 80%;
        padding: 20px 0px;
        min-width: 270px;
        top: 0px;
        background-color: $white;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
  
        &.opened {
          opacity: 1;
          left: 0px;
          animation: opening 0.7s ease-in-out;
        }
  
        @keyframes opening {
          0% {
            opacity: 0;
            left: -100%;
          }
          100% {
            opacity: 1;
            left: 0px;
          }
        }
  
        &.closed {
          opacity: 0;
          left: -100%;
          animation: closing 0.7s ease-in-out;
        }
  
        @keyframes closing {
          0% {
            opacity: 1;
            left: 0px;
          }
          100% {
            left: -100%;
            opacity: 0;
          }
        }
      }
    }

    &-nav {
      align-items: center;

      @include media-breakpoint-down(lg) {
        margin: 0;
        width: 100%;
        height: auto;
        display: block;
      }

      h3 {
        display: none;
        
        @include media-breakpoint-down(md) {
          display: block;
          font-size: 30px;
          padding: 0 25px 20px;
          margin-bottom: 20px;
        }
      }

      li {
        margin-left: 50px;
        position: relative;

        @include media-breakpoint-down(md) {
          margin-left: 36px;
        }
        
        a {
          color: $black;
          font-size: 16px;
          font-weight: 300;

          &.active, &:hover {
            color: $black-text;
          }

          @include media-breakpoint-down(md) {
            font-weight: 400;
          }
          
        }

        .button {
          color: $white;
          font-weight: 500;
          font-size: 14px;

          &:hover {
            color: darken(#fff, 10);
          }

          @include media-breakpoint-down(lg) {
            width: 90%;
            margin-top: 24px;
          }
        }
      }
    }

    .dropdown {
      &-item {
        padding: 0;
        font-weight: 400;
        margin-bottom: 16px;
        color: $black-text;

        @include media-breakpoint-down(md) {
          white-space: break-spaces;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
        
        &:active, &:hover {
          color: lighten($black-text, 60%);
          background: transparent;
        }

      }
      
      &-divider {
        display: none;
      }

      &-menu {
        margin-top: -5px;
        background-color: transparent;
        
        &-wrap {
          overflow: hidden;
          background-color: white;
          padding: 20px;
          position: relative;
          margin-top: 10px;
          border-top: 3px solid $black;

          @include media-breakpoint-down(md) {
            padding: 17px;
          }
        }
      }

      &-toggle {
        &:after {
          border: none;
          content: url(../../assets/images/icons/chevron-down.svg);
          margin-left: 8px;
        }
      }
    }

    &-toggler {
      padding: 0;
      display: flex;
      justify-content: space-between;
      width: 25px;
      flex-direction: column;
      border: none;
      position: relative;
      display: none;

      @include media-breakpoint-down(lg) {
        display: flex;
      }
      
      &-icon {
        width: 100%;
        height: 2px;
        margin-bottom: 4px;
        transition: .3s all;
        position: relative;
        display: block;
        background-color: $black;
      }
      
      
      &.opened {   

        .navbar-toggler-icon {
          display: block;
          background-color: $black;

          &.first {
            transform: rotate(45deg);
            top: 3px;
          }
          &.second {
            display: none;
          }
          &.third {
            transform: rotate(-45deg);
            top: -3px;
          }
        }

        .dropdown-toggle::after {
          transform: rotate(180deg);
        }
      }
    }

    &-overlay {
      position: fixed;
      background-color: rgba($black, 0.7);
      opacity: 0.8;
      width: 100%;
      height: 100%;
      top: 116px;
      left: 0;
      display: none;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        top: 82px;
      }
    }
  }
}
