.services-hero {
  position: relative;
  width: 100%;

  &-img {
    width: 100%;
    position: relative;
    margin: 177px 0px -12.5%;
    z-index: 2;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      position: relative;

      @include media-breakpoint-down(md) {
        height: 100%;
        width: auto;
        left: -30%;
      }
    }

    @include media-breakpoint-down(md) {
      height: 30vw;
      margin: 120px 0px -12.5%;
    }
  }
}