.help {
  padding: 76px 0px;
  position: relative;

  &-bcg {
    position: absolute;
    top: 0;
    left: 0;
  }

  h2 {
    text-align: center;
    font-size: 36px;
    color: $black;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 20px;

    &:nth-child(2) {
      color: white;
      -webkit-text-stroke: 1px $black;
    }
  }

  p {
    font-size: 18px;
    line-height: 29px;
    font-weight: 300;
    margin: 25px 0px 28px 0px;
    text-align: center;
  }


  .button {
    margin: 0 auto;
    padding: 16px 58px;
    width: 100%;
    max-width: 465px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    span {
      position: relative;
      font-size: 16px;
      color: white;
    }
  }
}