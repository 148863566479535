/** Buttons **/

a {
  display: inline-flex;
  text-decoration: none;
  transition: all .3s ease;
  outline: none;

  &:hover {
    text-decoration: none;
  }
}

button {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  background-color: transparent;
  cursor: pointer;
  transition: all .3s ease;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}