.hero {
  width: 100%;
  position: relative;
  overflow: hidden;

  &-bcg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: 220px;

    @include media-breakpoint-down(md) {
      margin-top: 150px;
    } 

    .row {
      justify-content: space-between;
      padding: 0px 24px;
    }

    &-small-t {
      position: absolute;
      left: -20px;
      top: 90px;

      @include media-breakpoint-down(md) {
        left: -48px;
      }

      @include media-breakpoint-down(sm) {
        top: 46px;
      }
    }

    &-title {
      margin-bottom: 200px;
      width: 80%;
      
      @media screen and (max-width: 480px) {
        margin-bottom: 80px;
      }

      h1 {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
          font-size: 36px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 28px;
        }

        @media screen and (max-width: 540px) {
          margin-bottom: 8px;
          font-size: 18px;
        }

        &:nth-child(2) {
          color: white;
          -webkit-text-stroke: 2px black;
          
          @include media-breakpoint-down(sm) {
            -webkit-text-stroke: 1px black;
          }
        }
      }
    }

    &-text {
      margin-bottom: 56px;
      position: relative;
      z-index: 3;

      @include media-breakpoint-down(lg) {
        margin-bottom: 120px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 108px;
        max-width: 50%;
      }

      @media screen and (max-width: 540px) {
        margin-bottom: 75px;
        max-width: 80%;
      }

      .button {
        margin-top: 46px;

        @include media-breakpoint-down(md) {
          margin-top: 16px;
          max-width: 100%;
        }

        @media screen and (max-width: 480px) {
          min-height: 20px;
          padding: 12px 40px;
        }
      }
    }
  }
  

  &-circle {

    &.second {
      position: relative;
      transform: scale(-1,1);
      right: -137px;
      top: -19px;
    }

    &.third {
      position: relative;
      right: -585px;
      margin-top: -21px;

      @include media-breakpoint-down(xxl) {
        right: -580px;
      } 
    }
  }

  &-zig-zag {
    position: absolute;
    bottom: 0;
    right: 130px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &-line {
    position: absolute;
    bottom: 0px;
    right: 132px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      right: 64px;
      height: 150vh;
    }

    @include media-breakpoint-down(md) {
      right: 0;
      left: 210px;
    }

    @include media-breakpoint-down(sm) {
      left: 64px;
    }

    .desctop {
      position: absolute;
      right: 0;
      bottom: 0;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .mobile {
      display: none;

      @include media-breakpoint-down(lg) {
        display: block;
        height: 81%;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      @include media-breakpoint-down(md) {
        height: 80%;
        left: 0;
      }

      @media screen and (max-width: 650px) {
        height: auto;
        width: 80%;
      }
    }
  }
}