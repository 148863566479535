.services {
  position: relative;
  text-align: center;
  height: auto;
  background-color: $black;
  padding-bottom: 300px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 230px;
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }

  &.start {
    padding-top: 330px;

    @include media-breakpoint-down(lg) {
      padding-top: 230px;
    }

    .services {  
      &-zig-zag {
        display: none;
      }

      &-content {
        &-title {
          padding-top: 0%;

          h1 {
            margin-bottom: 12px;
          }
        }
      }

      &-line {
        position: absolute;
        left: 0;
        top: 9%;
        width: 20%;

        .mobile {
          display: none;
        }

        img {
          position: absolute;
          right: 0;
          width: auto;
        }
      }
    }
  }

  &-line {
    width: 100%;
    position: absolute;
    top: 0;
    right: 474px;

    .desctop {
      position: absolute;
      top: 0;
      width: 61%;
      right: 0;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .mobile {
      display: none;
    }

    @include media-breakpoint-down(md) {
      right: 0;
      left: 210px;

      .mobile {
        display: block;
        height: 55px;
      }

      .desctop {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      left: 64px;
    }

    .row {
      width: 100%;
    }
  }

  &-zig-zag {
    position: absolute;
    top: 0;
    right: 130px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  span {
    font-size: 16px;
    color: white;
    position: relative;
    text-align: left;
    font-weight: 300;
  }

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &-wrap {
      position: relative;
    }
    
    &-hero {
      position: relative;
      margin-top: 177px;
      z-index: 2;

      .col-lg-6 {
        &:nth-child(2) {
          @include media-breakpoint-down(md) {
            order: -2;
          }
        }
      }

      &-text {
        @include media-breakpoint-down(md) {
          padding-bottom: 36px;
        }

        h2 {
          color: $black;
          font-size: 36px;
          text-align: left;
          line-height: 1.2;
        }

        p {
          margin: 28px 0px;
        }
      }

      &-img {
        overflow: hidden;
        height: calc(100% + 40px);

        @include media-breakpoint-down(md) {
          height: auto;
          margin-bottom: 48px;
        }

        img {
          width: auto;
          height: 100%;

          @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    
    &-title {
      position: relative;
      margin-left: 128px;
      padding-top: 20%;
      text-align: left;
      width: 100%;

      &-wrap {
        margin-right: 50px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
          text-align: center;
        }
      }

      .row {
        width: 100%;
        justify-content: center;
      }

      @include media-breakpoint-down(lg) {
        margin-left: 0;
        padding: 0px 24px;
        padding-top: 100px;
      }

      h1 {
        color: white;
        font-size: 96px;
        margin-bottom: 28px;

        @include media-breakpoint-down(lg) {
          font-size: 56px;
          margin-bottom: 5px;
        }

        @media screen and (max-width: 540px) {
          font-size: 36px;
        }
      }

      &-line {
        img {
          position: relative;
          margin-top: 80px;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &-line {
      position: relative;
      height: 100%;
      margin-top: calc(100% - 50px);

      &.first {
        margin-top: 5px;
      }

      &.final {
        position: absolute;
        left: 9%;
        width: 83%;
        height: auto;
        bottom: 0px;

        @include media-breakpoint-down(lg) {
          width: 95%;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      
      img {
        position: relative;
      }
    }

    &-item {
      padding: 0px 54px;
      border-left: 2px solid $white;
      border-right: 2px solid $white;
      box-sizing: border-box;
      position: relative;
      z-index: 2;

      @include media-breakpoint-down(lg) {
        &:last-child {
          margin-bottom: 36px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0px 12px;
      }

      &-img {
        width: 100%;
        overflow: hidden;
        max-height: 142px;

        img {
          width: 100%;
          height: auto;
        }
      }

      h4 {
        color: white;
        font-size: 24px;
        text-align: left;
        position: relative;
        margin-top: 32px;

        @include media-breakpoint-down(sm) {
          margin-top: 16px;
        }
      }

      p {
        text-align: left;
        color: white;
        font-weight: 100;
        position: relative;
        margin-top: 16px;

        @include media-breakpoint-down(sm) {
          margin-top: 8px;
        }
      }

      .button {
        margin-top: 64px;

        @include media-breakpoint-down(sm) {
          margin-top: 36px;
        }
      }

      &-connect {
        display: none;

        @include media-breakpoint-down(lg) {
          display: block;
          position: relative;
          margin: 36px auto;
        }
      }
    }

    &-items {
      
      @include media-breakpoint-down(lg) {
        position: relative;
        margin-top: 48px;
        padding-bottom: 180px;

        .row {
          justify-content: center;
        }
        .col-lg-4 {
          display: none;
        }

        .col-lg-6 {
          margin: 0 auto;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }

    &-wrap {
      background-color: $black;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-top: 104px;
      padding-bottom: 128px;

      &-line {
        position: relative;
        width: calc(100% - 120px);

        &.second {
          transform: scale(-1, 1);
          align-self: flex-end;
        }
      }

      &-items {
        position: relative;
        padding: 64px 0px;
      }

      &-item {
        position: relative;
        margin-bottom: 128px;

        &:last-child {
          margin-bottom: 0px;
        }

        h3 {
          font-size: 24px;
          color: $white;
        }

        p {
          color: #c4c4c4;
          font-size: 16px;
          margin-top: 16px;
          line-height: 24px;
          text-align: justify;
          font-weight: 100;
        }

        .list-checked {
          margin-left: 0;
          
          span {
            color: $white;
          }
        }

        &-img {
          overflow: hidden;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;

          @include media-breakpoint-down(md) {
            height: 350px;
            margin: 36px 0px;
          }
        }
      }
    }
  }
}