// ############ Colors ############

$green-bcg: #EDF7F0;
$green-bcg-2: #6BFA9C;

$black-text: #263238;
$black-text-p: #505050;

$gray:#9DA39F;
$gray-input: #F8F8F8;

$black: #000;  