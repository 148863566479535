// ############ Fonts ############

@mixin font-face ($name, $file-name, $font-weight, $font-style: normal) {
  @font-face {
      font-family: $name;
      src: url('../fonts/#{$file-name}.tff') format('ttf'),
          url('../fonts/#{$file-name}.woff') format('woff');
      font-weight: $font-weight;
      font-style: $font-style;
  }
}


$Poppins: 'Poppins';
$Druk: "Druk Wide Bold";

@include font-face($Poppins, 'Poppins-Thin', 100);
@include font-face($Poppins, 'Poppins-Light', 300);
@include font-face($Poppins, 'Poppins-Regular', 400);
@include font-face($Poppins, 'Poppins-Medium', 500);
@include font-face($Poppins, 'Poppins-SemiBold', 600);
@include font-face($Poppins, 'Poppins-Bold', 700);
@include font-face($Druk, $Druk, 700);


$font-primary: $Poppins, sans-serif;
$font-title: $Druk, sans-serif;