.about-hero {
  padding-top: 173px;

  &-bcg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-img {
    overflow: hidden;
    z-index: 1;
    position: relative;

    img {
      height: 100%;
      width: auto;
      margin-left: -50%;
    }

    &-wrap {
      @include media-breakpoint-down(md) {
        order: -1;
      }
    }
  }

  h1 {
    font-size: 36px;

    @include media-breakpoint-down(md) {
      font-size: 36px;
      margin-top: 36px;
    }
  }

  p {
    margin: 24px 0px;
    font-size: 16px;
    line-height: 160%;

  }

  .list-checked {
    margin-bottom: 36px;
  }
}