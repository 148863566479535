.footer {
  padding: 64px 0px 40px;
  background-color: #060707;

  .container {

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 32px 120px;
  }

  @include media-breakpoint-down(md) {
    padding: 32px 64px;
  }

  @include media-breakpoint-down(sm) {
    padding: 32px 24px;
  }

  &-logo {
    
    @include media-breakpoint-down(md) {
      max-width: 50%;
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    img {
      width: 100%;
    }
  }

  span {
    color: rgba(255,255,255,0.6);
  }
  
  &-questions {
    margin-top: 53px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }

  &-contact {
    margin-top: 32px;
    position: relative;
    display: flex;
    align-items: center;
  }

  h4 {
    font-weight: 600;
    font-size: 18px;
    font-family: $font-title;
    color: $white;
    
    a {
      font-weight: 600;
      font-size: 18px;
      color: $white;
    }
  }

  ul {
    list-style: none;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    li {
      margin-top: 12px;

      a {
        font-size: 15px;
        font-weight: 100;
        color: rgba(255,255,255,0.6);
      }
    }
  }

  &-copyright {
    position: relative;
    width: 100%;
    margin-top: 80px;

    span, a {
      color: rgba(255,255,255,0.5);
      font-size: 14px;
      line-height: 27px;
      font-weight: normal;
    }

    a {
      text-decoration: none;
      margin-left: 2px;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
